import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layouts/Default"
import Breadcrumb from "../../components/common/Breadcrumb"
import Container from "../../components/layouts/container/Container"
import SectionTitle from "../../components/common/SectionTitle"
import Cta from "../../components/common/Cta"
import HeaderImage from "../../components/common/HeaderImage"
import ColumnsThree from "../../components/layouts/container/ColumnsThree"
import ServiceSidebar from "../../components/common/ServiceSidebar"
import P from "../../components/typography/P"

export default function Page({ data, location }) {
  const svg = {
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
  }
  return (
    <Layout
      location={location}
      title="Erstellung Energieausweis | Döbbel Immobilien"
      name="Leistungen"
      desc=""
    >
      <HeaderImage img={data.header.childImageSharp.fluid} />
      <Breadcrumb
        links={[
          { link: "Leistungen", to: "/leistungen/" },
          {
            link: "Erstellung Energieausweis",
            to: "/leistungen/energieausweis/",
          },
        ]}
      />
      <Container className="my-8 md:my-16">
        <ColumnsThree>
          <div className="md:col-span-2">
            <SectionTitle
              title="Erstellung Energieausweis"
              subtitle="Leistungen"
              text="Der Energieausweis ist ein Dokument, das Daten zur Energieeffizienz und zu den anfallenden Energiekosten eines Gebäudes liefern soll."
              className="mb-6"
            />
            <P className="mb-3">
              Sie brauchen einen neuen Energieausweis? Zur Ausstellung von
              Energieausweisen sind nur Personen mit einer besonderen
              Qualifizierung berechtigt. Den Energieausweis dürfen nach dem
              Gebäudeenergiegesetz (GEG) nur Personen mit besonderen Aus- oder
              Weiterbildungen sowie Berufspraxis ausstellen. Dazu zählen unter
              anderem wir.
            </P>
            <P className="mb-3">
              Das GEG zählt sämtliche Voraussetzungen auf. Daher sind für Sie
              als Auftraggeber verlässliche Angaben des Ausstellers wichtig,
              dass er die Anforderungen des GEG erfüllt.{" "}
            </P>
          </div>
          <div>
            <ServiceSidebar name="Erstellung Energieausweis" />
          </div>
        </ColumnsThree>
      </Container>

      <Container className="my-8 md:my-16">
        <Cta />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "energieausweis.jpg" }) {
      childImageSharp {
        fluid(jpegQuality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
